import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <section id="404" className="main style1 special">
      <div className="container">
        <header className="major">
          <h1>NOT FOUND</h1>
        </header>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
